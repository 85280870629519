export const blogs = [
    // {
    //   id: 1,
    //   title: 'Typography',
    //   date: '21 June 2020 08:04 AM',
    //   image: 'https://via.placeholder.com/300',
    //   excerpt: 'Lid est laborum et dolor... ',
    //   content: 

 
   

    // Add more blog objects here
  ];
  